import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo, useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { CmRefund } from "../../types";
import Navbar from "../navbar/Navbar";
import {
  usePaymentsByUserQuery,
  useRefundsByUserQuery,
} from "./subscriptionApiSlice";
import { Column, TableOptions, useTable } from "react-table";
import { formatDateOnly, getPriceLocalized } from "../../utils";
import { useCurrentUserQuery } from "../user/userApiSlice";
import Back from "../../components/Back";
import Footer from "../footer/Footer";

interface BillingHistoryProps {
  subscriptionId: string;
}

interface Cols {
  created: string;
  amount: string;
  receipt: string | null;
  refund: CmRefund | undefined;
}
const BillingHistory = ({ subscriptionId }: BillingHistoryProps) => {
  const [payments, setPayments] = useState<Cols[]>([]);
  const { data: currentUser } = useCurrentUserQuery();
  const {
    data: allPayments,
    isSuccess: isSuccessPayments,
    isFetching: isFetchingPayments,
  } = usePaymentsByUserQuery(currentUser?.data?.uid ?? skipToken);
  const { data: allRefunds, isFetching: isFetchingRefunds } =
    useRefundsByUserQuery(currentUser?.data?.uid ?? skipToken);

  useEffect(() => {
    if (allPayments && Array.isArray(allRefunds) && isSuccessPayments) {
      const subscriptionPayments = allPayments.filter(
        (item) => item.subscription === parseInt(subscriptionId)
      );
      if (subscriptionPayments.length === 0) {
        setPayments([
          {
            created: "No payments have been made for this subscription",
            amount: "",
            receipt: null,
            refund: undefined,
          },
        ]);
      } else {
        setPayments(
          subscriptionPayments
            .sort((a, b) => (a.created > b.created ? -1 : 1))
            .map((item) => ({
              created: formatDateOnly(item.created),
              amount: `${getPriceLocalized(
                item.currency,
                item.sale_gross
              )} including tax`,
              receipt: item.receipt_url,
              refund: allRefunds.find(
                (refund) => refund.cm_payment_id === item.id
              ),
            }))
        );
      }
    }
  }, [isFetchingPayments, isFetchingRefunds]);

  const columns: Column<Cols>[] = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "created",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "",
        accessor: "receipt",
        Cell: ({ cell: { value } }) => {
          return (
            value && (
              <a className="text-blue-700 hover:text-blue-800" href={value}>
                View receipt
              </a>
            )
          );
        },
      },
      {
        Header: "",
        accessor: "refund",
        Cell: ({ cell: { value: refund } }) => {
          if (typeof refund === `undefined`) return null;
          return (
            <>
              {getPriceLocalized(refund.currency, refund.gross_refund)} refunded
              on {formatDateOnly(refund.created)}
            </>
          );
        },
      },
    ],
    []
  );

  const options: TableOptions<Cols> = { data: payments, columns };

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(options);

  return (
    <>
      <div className="min-h-screen">
        <Navbar />
        <div className="px-4 md:px-0 md:w-3/4 lg:w-1/2 mx-auto mt-10">
          <div className="mb-4 text-2xl">
            <Back to="/user/settings" />
          </div>
          <div className="mb-4">
            <SectionTitle backgroundStyle="gray" title="Billing history" />
            <div className="flex flex-col mt-2">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow-sm ">
                    <table
                      className="min-w-full divide-y divide-neutral-200"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map((headerGroup, index) => (
                          <tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column) => (
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-neutral-700 sm:pl-6 lg:pl-8"
                                key={column.id}
                                {...column.getHeaderProps}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody
                        className="divide-y divide-neutral-100 bg-white"
                        {...getTableBodyProps()}
                      >
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr key={row.id} {...row.getRowProps()}>
                              {row.cells.map((cell, index) => (
                                <td
                                  className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-neutral-600 sm:pl-6 lg:pl-8"
                                  key={index}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BillingHistory;
