import { HeadProps, PageProps } from "gatsby";
import React from "react";
import Seo from "../../../features/seo/Seo";
import BillingHistory from "../../../features/subscription/BillingHistory";
import { useRequireAuthentication } from "../../../features/user/hooks";

const BillingHistoryPage = (props: PageProps) => {
  useRequireAuthentication();

  return <BillingHistory subscriptionId={props.params.id} />;
};

export default BillingHistoryPage;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Billing history | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
